<template>

  <div class="page-wrapper">
    <b-card no-body>
      <b-tabs card>
        <span v-if="error" class="error">{{ error }}</span>
        <b-tab title="Account" active>
          <b-form>
            <inline-form-row label="Username" type="text" :disabled="!this.isNew" v-model="user.username"
              :errors="errors['username']" helpText="Username should be composed of lower cases, [first name's first letter + last name's], no number, no special character"/>
            <inline-form-row label="Email" type="text" v-model="user.email" :errors="errors['email'] " helpText="The user's business email"/>
            <inline-form-row label="Role" _type="b-form-select" :options="this.roles" v-model="user.role"
              :errors="errors['role']" helpText="Select a role"/>
            <inline-form-row label="Password" type="password" v-model="user.password" :errors="errors['password']" helpText="Contain a mix of uppercase and lowercase letters, punctuation, numbers, and symbols. Contain at least 8 characters"/>
            <inline-form-row label="First name" type="text" v-model="user.firstname" :errors="errors['firstname']" helpText="User's first and middle name(s)"/>
            <inline-form-row label="Last name" type="text" v-model="user.lastname" :errors="errors['lastname']" helpText="User's last name"/>
            <inline-form-row label="Phone" type="text" v-model="user.phone" :errors="errors['phone']" placeholder="+15555555555" helpText="Phone Number format should be: +[country code][area code][phone number]" />
            <inline-form-row label="Start Date" type="date" v-model="user.start_date" :errors="errors['start_date']" helpText="Hiring date"/>
          </b-form>
        </b-tab>
        <b-tab title="Personal">
          <b-form>
            <inline-form-row label="Emergency Phone Number" type="text" v-model="user.emergency_phone"
              :errors="errors['emergency_phone']" placeholder="+15555555555" helpText="Personal Phone number" />
            <inline-form-row label="Contact Type" type="text" v-model="user.contact_type"
              :errors="errors['contact_type']" helpText="Consultant/Employee/Client/Partner/other" />
            <inline-form-row label="Contact Name" type="text" v-model="user.contact_name"
              :errors="errors['contact_name']" helpText="Full Name" />
            <inline-form-row label="Personal email" type="text" v-model="user.personal_email"
              :errors="errors['personal_email']" helpText="Personal email address" />
            <inline-form-row label="Birth Date" type="date" v-model="user.birth_date" :errors="errors['birth_date']" helpText="Birth date" />
            <BMSAddress v-model="user.address" />
            <inline-form-row label="Gender" _type="b-form-select" :options="options" v-model="user.gender"
              :errors="errors['gender']" helpText="Gender M/F/O" />
          </b-form>
        </b-tab>
        <b-tab title="Notification">
    <b-form-group label="Category of notifications:">
      <b-form-checkbox-group
        id="checkbox-notification-group"
        v-model="categoryNotification"
        name="categoryNotification"

      >
        <b-form-checkbox value="user">Users</b-form-checkbox>
        <b-form-checkbox value="invoice">Invoices</b-form-checkbox>
        <b-form-checkbox value="client">Clients</b-form-checkbox>
        <b-form-checkbox value="project">Projects</b-form-checkbox>
        <b-form-checkbox value="time">Timesheets</b-form-checkbox>
        <b-form-checkbox value="quote">Quotes</b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
        </b-tab>
        <b-tab title="Audit Trail" disabled />
      </b-tabs>
    </b-card>
    <div class="text-right">
      <b-button @click="onSave()" variant="primary" :disabled="!isComplete">
        Save
      </b-button>
      <b-button v-if="user.deleted == false" @click="onDelete()" variant="danger" :class="{invisible : isNew}">
        Delete
      </b-button>
      <b-button v-if="user.deleted == true" @click="onActivate()" variant="success" :disabled="isNew">
        Activate
      </b-button>
    </div>
  </div>

</template>

<script>

import { getAllRoles } from '@/api/roles.api'
import { getUser, createUser, updateUser, deleteUser, activateUser} from '@/api/users.api'
import BMSAddress from '@/components/BMSAddress'
import InlineFormRow from '@/components/InlineFormRow'
import crudMixin from '@/api/crud'
import confirmationModal from '@/api/confirmation'

export default {
  mixins: [crudMixin, confirmationModal],
  data() {
    return {
      id: this.$route.params.id,
      roles: [],
      user: { address: {} },
      options: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' },
      ],
    }
  },
  computed: {
    isNew: function () {
      return this.$route.params.id != parseInt(this.$route.params.id)
    },
    isComplete(){
      let user = this.user
      if (!user.username || !user.email || !user.role || !user.phone){
        return false
      }
       return true
    }
  },
  mounted() {
    this.getRoles();
    if (!this.isNew) {
      this.getUser();
    }
  },
  components: {
    InlineFormRow,
    BMSAddress,
  },
  methods: {
    getRoles() {
      this.call(
        getAllRoles(),
        (response) => {
          response.data.roles.forEach(role => {
            this.roles.push(role)
          })
        }
      )
    },
    getUser() {
      this.call(
        getUser(this.id),
        (response) => {
          this.user = response.data.user
        }
      )
    },
    onSave: function () {
      if (this.isNew) {
        var apiCall = createUser(this.user)
      } else {
        apiCall = updateUser(this.id, this.user)
      }
      this.saveObject(apiCall)
    },
    onDelete: function () {
      // confirmModal(message, okTitle, cancelTitle)
      this.confirmModal().then((result) => {
        if (result){
        if (!this.isNew) {
        this.deleteObject(
          deleteUser(this.id),
          () =>{
            this.$router.push('/users')
          }
        )
      }
      }
      })
    },
    onActivate: function(){
      // confirmModal(message, okTitle, cancelTitle, okVariant {default: danger})
      this.confirmModal("Are you sure?", "ACTIVATE", "CANCEL", "success").then((result) => {
        if (result){
          if (!this.isNew) {
        this.saveObject(
          activateUser(this.id),
          () =>{
            this.$router.push('/users')
          }
        )
          }
      }
      })
    }
  }
}
</script>
<style>
  .invisible{
    display: none;
  }
</style>