import httpClient from './httpClient';

const END_POINT = '/roles';


const getAllRoles = () => httpClient.get(END_POINT);

export {
    getAllRoles
}

